<script>
import TradingNetworksForm from './TradingNetworksForm'

export default {
  extends: TradingNetworksForm,

  methods: {
    onSubmit() {
      this.createTradingNetwork(this.form)
        .then(() => {
          this.successSubmit({ message: 'Торговая сеть успешно создана', routeName: 'trading-networks' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>